import * as Yup from "yup";

const addPlanValues = {
  title: "",
  price: "",
  monthType: "",
  type: "",
};

//  validations schemas
const addPlanValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required*")
    .trim()
    .matches(
      /^[a-zA-Z0-9_ ]+$/,
      "Title can only contain letters, numbers, underscores, and spaces"
    ),
  price: Yup.number()
    .typeError("Price must be a valid number*")
    .required("Price is required*")
    .min(1, "Price must be at least 1*"),
  type: Yup.string().required("Type is required*"),
  monthType: Yup.string().matches(/^[1-9][0-9]*$/, "Enter valid number"),
});
const addMonthValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required*")
    .trim()
    .matches(
      /^[a-zA-Z0-9_ ]+$/,
      "Title can only contain letters, numbers, underscores, and spaces"
    ),
  price: Yup.number()
    .typeError("Price must be a valid number*")
    .required("Price is required*")
    .min(1, "Price must be at least 1*"),
  type: Yup.string().required("Type is required*"),
  monthType: Yup.string()
    .required("Time Period is required*")
    .matches(/^[1-9][0-9]*$/, "Enter valid number"),
});
const secondComponentValidationSchema = Yup.object().shape({
  price: Yup.number()
    .typeError("Price must be a valid number*")
    .required("Price is required*")
    .min(1, "Price must be at least 1*"),
});
const combinedValidationSchema = addPlanValidationSchema.concat(
  secondComponentValidationSchema
);

const addTaxValues = {
  tax: "",
};
const addTaxValidationSchema = Yup.object().shape({
  tax: Yup.number()
    .typeError("Tax must be a valid number*")
    .required("Tax value is required*")
    .moreThan(0, "Tax must be greater than 0*")
    .lessThan(100, "Tax must be less than 100*"),
});

export {
  addPlanValues,
  combinedValidationSchema,
  addMonthValidationSchema,
  addTaxValues,
  addTaxValidationSchema,
};
