import React, { useEffect, useState } from "react";
import { useGetKeyHostDetails } from "../../hooks/keyHostManagementApi/getKeyHostDetails";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/content_wrapper/header";
import { EditSvg, EyeSvg, HistorySvg } from "../../utils/svgComponent";
import moment from "moment";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  keyHostChangePasswordSchema,
  keyHostChangePasswordValues,
} from "../../validations";
import { useChangeKeyHostPassword } from "../../hooks/keyHostManagementApi/changePassword";
import CustomLaoder from "../../components/customLoader";
import { el } from "date-fns/locale";

const KeyHostDetailScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mutateAsync } = useChangeKeyHostPassword();
  const [showEye, setShowEye] = useState(false);
  const [showConfirmEye, setShowConfirmEye] = useState(false);

  const data = location.state;
  const { data: GetKeyHostDetail, isLoading } = useGetKeyHostDetails({
    id: data?.id,
  });
  const hostDetail = GetKeyHostDetail?.data?.keyHostDetails;
  const handleClick = (id: any) => {
    navigate("/boxmanagementdetail", {
      state: {
        id,
      },
    });
  };
  const handleClickKey = (id: any) => {
    navigate("/keymanagementdetail", {
      state: {
        id,
      },
    });
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: keyHostChangePasswordValues,
    validationSchema: keyHostChangePasswordSchema,
    onSubmit: async () => {
      try {
        const res: any = await mutateAsync({
          email: values?.email,
          password: values?.confirmPassword,
        });
        if (res?.status === true) {
          toast.success(res?.message);
          resetForm();
          const closeModelButton: any = document.getElementById("close-btn");
          closeModelButton.click();
        }
      } catch (error: any) {
        toast.dismiss();
        toast.error(error?.response?.data?.message);
      }
    },
  });
  useEffect(() => {
    setFieldValue("email", hostDetail?.email);
  }, [hostDetail]);

  return (
    <div className="main-wrapper">
      <Header title="Host Detail" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section profile-section ">
            <div className="container">
              <button
                className="btn primary-btn me-0 view-history-btn my-3"
                onClick={() => navigate(-1)}
              >
                <i className="fa-solid fa-chevron-left" />{" "}
                <span className="m-lg-1">Back</span>
              </button>
              <div className="wrapper-card">
                <div className="flex-end-box">
                  <button
                    className="btn primary-btn me-0 view-history-btn mb-3"
                    data-bs-toggle="modal"
                    data-bs-target="#changePasswordModal"
                  >
                    Change Password
                  </button>
                </div>
                <div className="row gy-3">
                  <div className="col-xl-12">
                    <div className="info-card">
                      <div className="row justify-content-between gy-3">
                        <div className="col-12 col-sm-6">
                          <div className="info-flex-detail">
                            <div className="info-detail">
                              <h2 className="h4">Username</h2>
                              <p className="mb-0">{hostDetail?.user_name}</p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Email address</h2>
                              <p className="mb-0">{hostDetail?.email}</p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Login ID</h2>
                              <p className="mb-0">
                                {hostDetail?.user_login_id}
                              </p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Login Password</h2>
                              <p className="mb-0">{hostDetail?.password}</p>
                            </div>
                            <div className="info-txt-detail">
                              <h2 className="h4">Stock info</h2>
                              <p className="mb-0">{hostDetail?.store_info}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="info-flex-detail">
                            <div className="info-detail">
                              <h2 className="h4">Address</h2>
                              <p className="mb-0">{hostDetail?.address}</p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Phone number</h2>
                              <p className="mb-0">
                                (+{hostDetail?.country_code}){" "}
                                {hostDetail?.phone_number}
                              </p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Store name</h2>
                              <p className="mb-0">{hostDetail?.store_name}</p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Total number of key hosted</h2>
                              <p className="mb-0">{hostDetail?.total_keys}</p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Total boxes assigned</h2>
                              <p className="mb-0">{hostDetail?.total_boxes}</p>
                            </div>
                            <div className="info-txt-detail">
                              <h2 className="h4">Hours of operation</h2>
                              <span className="hrs-opt">
                                {hostDetail?.active_times?.map((time: any) => (
                                  <div className="d-flex justify-content-between">
                                    <span
                                      style={{
                                        marginRight: "10px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {time?.day}
                                    </span>
                                    {time?.closed === false &&
                                    time.start_time !== "" ? (
                                      <span>
                                        {moment
                                          .utc(time.start_time, "HH:mm")
                                          .format("LT")}{" "}
                                        -{" "}
                                        {moment
                                          .utc(time.end_time, "HH:mm")
                                          .format("LT")}
                                      </span>
                                    ) : (
                                      "Closed"
                                    )}
                                  </div>
                                ))}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="sub-heading my-3">
                      <h2>Boxes details</h2>
                    </div>
                    <div className="table-blk">
                      <table id="myTable" className="display border-0">
                        <thead>
                          <tr>
                            <th>Box ID</th>
                            <th>Total cubicles</th>
                            <th>Login ID</th>
                            <th>Password</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {GetKeyHostDetail?.data?.boxDetails?.map(
                            (item: any, index: number) => (
                              <tr key={index}>
                                <td>{item.box_id}</td>
                                <td>{item.total_slots}</td>
                                <td>{item.login_id}</td>
                                <td>{item.password}</td>
                                <td>
                                  <span
                                    className={`c-pill 
                                    ${
                                      item.status === 1
                                        ? "c-pill--success"
                                        : "c-pill--danger"
                                    }`}
                                  >
                                    {item.status === 1 ? "Active" : "Suspended"}
                                  </span>
                                </td>
                                <td>
                                  <span className="table-action">
                                    <a
                                      className="table-eye"
                                      onClick={() => handleClick(item?._id)}
                                    >
                                      <EyeSvg />
                                    </a>
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {GetKeyHostDetail?.data?.boxDetails?.length === 0 ? (
                        <div className="d-flex justify-content-center align-items-center my-3">
                          <p className="m-0">No data found</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="sub-heading my-3">
                      <h2>Keys details</h2>
                    </div>
                    <div className="table-blk">
                      <table id="myTable" className="display border-0">
                        <thead>
                          <tr>
                            <th>Key name</th>
                            <th>Box ID</th>
                            <th>Cubicle Location</th>
                            {/* <th>Key host location</th> */}
                            <th>Subscription validity</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {GetKeyHostDetail?.data?.keyDetails?.map(
                            (data: any, index: number) => (
                              <tr key={index}>
                                <td>{data?.name}</td>
                                <td>{data?.box_id}</td>
                                <td>{data?.cubical_location}</td>
                                {/* <td>N/A</td> */}
                                <td>
                                  <span
                                    className={`c-pill 
                                    ${
                                      data?.isSubscriptionActive === true
                                        ? "c-pill--success"
                                        : "c-pill--danger"
                                    }`}
                                  >
                                    {data?.isSubscriptionActive === true
                                      ? "Active"
                                      : "Suspended"}
                                  </span>
                                </td>
                                <td>
                                  <span className="table-action">
                                    <a
                                      className="table-eye"
                                      onClick={() => handleClickKey(data?._id)}
                                    >
                                      <EyeSvg />
                                    </a>
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            className="modal fade common-modal"
            id="changePasswordModal"
            tabIndex={-1}
            aria-labelledby="changePassword"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    id="close-btn"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => resetForm()}
                  />
                  <div className="common-box-body">
                    <h2>Change Password</h2>

                    <div className="common-modal-form">
                      <div className="common-modal-field">
                        <div className="common-modal-field">
                          <div className="d-flex justify-content-between">
                            <label>Email</label>
                          </div>

                          <input
                            placeholder="Email"
                            className="form-control"
                            type="email"
                            name="email"
                            value={hostDetail?.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            readOnly
                          />

                          {touched.email && errors.email && (
                            <p className="error">{errors.email}</p>
                          )}
                        </div>
                        <div className="common-modal-field">
                          <div className="d-flex justify-content-between">
                            <label>New Password</label>
                          </div>
                          <div className="p-relative">
                            <span className="eye">
                              <i
                                className={
                                  !showEye
                                    ? "fa-regular fa-eye"
                                    : "fa-regular fa-eye-slash"
                                }
                                onClick={() => setShowEye(!showEye)}
                              />
                            </span>
                            <input
                              placeholder="Password"
                              className="form-control"
                              type={!showEye ? "password" : "text"}
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {touched.password && errors.password && (
                            <p className="error">{errors.password}</p>
                          )}
                        </div>
                        <div className="common-modal-field">
                          <div className="d-flex justify-content-between">
                            <label>Confirm New Password</label>
                          </div>
                          <div className="p-relative">
                            <span className="eye">
                              <i
                                className={
                                  !showConfirmEye
                                    ? "fa-regular fa-eye"
                                    : "fa-regular fa-eye-slash"
                                }
                                onClick={() =>
                                  setShowConfirmEye(!showConfirmEye)
                                }
                              />
                            </span>
                            <input
                              placeholder="Confirm Password"
                              className="form-control"
                              type={!showConfirmEye ? "password" : "text"}
                              name="confirmPassword"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {touched.confirmPassword &&
                            errors.confirmPassword && (
                              <p className="error">{errors.confirmPassword}</p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="common-modal-btns">
                      <button
                        className="btn primary-btn me-0"
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomLaoder>
    </div>
  );
};

export default KeyHostDetailScreen;
