import { EditSvg, EyeSvg } from "../../utils/svgComponent";
import Header from "../../components/content_wrapper/header";
import QRCode from "react-qr-code";
import { useGetBoxDetail } from "../../hooks/boxManagementApi/getBoxDetail";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUpdateCubicleStatus } from "../../hooks/boxManagementApi/cublicleStatus";
import { useEffect, useState } from "react";
import Pagination from "../../utils/pagination";
import { useAddCubicleDetails } from "../../hooks/boxManagementApi/addCubicleDetails";
import { useFormik } from "formik";
import { addCubeValidationSchema, addCubeValue } from "../../validations";
import { toast } from "react-toastify";
import CustomLaoder from "../../components/customLoader";

const Box_management_detail = () => {
  const navigate = useNavigate();
  const [cubeId, setCubeId] = useState("");
  const [countData, setCountData] = useState<any>(20);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [boxId, setBoxId] = useState<any>();
  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    if (data) {
      setBoxId(data?.id);
    }
  }, [data]);

  const { data: boxDetail, isLoading } = useGetBoxDetail({
    id: boxId,
    page: pageNumber,
    limit: countData,
  });
  const boxItemsDetail = boxDetail?.data;
  const { mutateAsync: update } = useUpdateCubicleStatus();
  const handlePrevPage = () => {
    setPageNumber((prevPage: any) => prevPage - 1);
  };
  const handleNextPage = () => {
    setPageNumber((nextPage: any) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue: number) => {
    setCountData(newValue);
  };

  //update Status
  const handleToggleClick = async (cube_id: any, action: boolean) => {
    let Status = action === true ? false : true;
    try {
      const res: any = await update({
        _id: boxId,
        cube_id: cube_id,
        status: Status,
      });
      if (res.success) {
        console.log(res);
      } else {
        console.error("Failed to update user status:", res.error);
      }
    } catch (error: any) {
      console.error("Unexpected error:", error);
    }
  };

  //rendom key
  function generateRandomString(length: any) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }
  const randomString = generateRandomString(10);

  // Add Cube detail
  const { mutateAsync } = useAddCubicleDetails();
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: addCubeValue,
    validationSchema: addCubeValidationSchema,
    onSubmit: async () => {
      try {
        const res: any = await mutateAsync({
          box_id: cubeId,
          slot_number: values.cubeNumber,
          keyring_id: randomString,
        });
        if (res?.status === true) {
          toast.success(res?.message);
          resetForm();
          const closeModelButton: any = document.getElementById("close-btn");
          closeModelButton.click();
        }
        if (res?.status === false) {
          toast.error(res?.message);
          resetForm();
          const closeModelButton: any = document.getElementById("close-btn");
          closeModelButton.click();
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });
  console.log(errors);

  return (
    <div className="main-wrapper">
      <Header title="Box detail" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section profile-section">
            <div className="container">
              <button
                className="btn primary-btn me-0 view-history-btn my-3"
                onClick={() => navigate(-1)}
              >
                <i className="fa-solid fa-chevron-left" />{" "}
                <span className="m-lg-1">Back</span>
              </button>
              <div className="wrapper-card">
                <div className="row gy-3">
                  <div className="col-xl-12">
                    <div className="box-detail-card flex-box align-items-start">
                      <div className="box-flex-card">
                        <div className="qr-blk">
                          <QRCode value={boxItemsDetail?.password} />
                        </div>
                        <div className="box-detail">
                          <div className="info-detail flex-box">
                            <h2 className="h4">Box ID</h2>
                            <p className="mb-0">{boxItemsDetail?.box_id}</p>
                          </div>
                          <div className="info-detail flex-box">
                            <h2 className="h4">Login ID</h2>
                            <p className="mb-0">{boxItemsDetail?.login_id}</p>
                          </div>
                          <div className="info-detail flex-box">
                            <h2 className="h4">Password</h2>
                            <p className="mb-0">{boxItemsDetail?.password}</p>
                          </div>
                          <div className="info-detail flex-box">
                            <h2 className="h4">
                              Total number of keys/Cubicles
                            </h2>
                            <p className="mb-0">
                              {boxItemsDetail?.total_cubicales}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <button
                        className="btn primary-btn me-0"
                        data-bs-toggle="modal"
                        data-bs-target="#addCubicle"
                        onClick={() => setCubeId(boxItemsDetail.box_id)}
                      >
                        +Add Cubicle details
                      </button> */}
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="sub-heading my-3">
                      <h2>Cubicle details</h2>
                    </div>
                    <form className="my-3">
                      <div className="row justify-content-between g-3">
                        <div className="col-lg-8 col-xl-8 col-xxl-6"></div>
                      </div>
                    </form>
                    <div className="table-blk">
                      <table id="myTable" className="display border-0">
                        <thead>
                          <tr>
                            <th>Cube Number</th>
                            <th>Cube ID</th>
                            <th>Added Key</th>
                            <th>link status</th>
                            <th>Key status</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {boxItemsDetail?.cubicle_details?.map(
                            (cubicalItems: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{cubicalItems?.cube_number}</td>
                                  <td>{cubicalItems?.cube_id}</td>
                                  <td>{cubicalItems?.added_key}</td>
                                  <td>
                                    {cubicalItems.key_status === ""
                                      ? null
                                      : cubicalItems?.link_status}
                                  </td>
                                  <td>
                                    {/* {cubicalItems?.key_status} */}
                                    <span
                                      // className={`table-status ${
                                      //   cubicalItems.key_status === 1
                                      //     ? "st-active"
                                      //     : cubicalItems.key_status === 0
                                      //     ? "st-pending"
                                      //     : "st-suspended"
                                      // }`}
                                      className={`c-pill 
                                      ${
                                        cubicalItems.key_status === 1
                                          ? "c-pill--success"
                                          : cubicalItems.key_status === 0
                                          ? "c-pill--warning"
                                          : "c-pill--danger"
                                      }`}
                                    >
                                      {cubicalItems.key_status === 0
                                        ? "Waiting dropoff"
                                        : cubicalItems.key_status === 1
                                        ? "In Keybox"
                                        : cubicalItems.key_status === 2
                                        ? "Not In Keybox"
                                        : "Not Added"}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      // className={`table-status ${
                                      //   cubicalItems.status
                                      //     ? "st-active"
                                      //     : "st-suspended"
                                      // }`}
                                      className={`c-pill 
                                      ${
                                        cubicalItems.status
                                          ? "c-pill--success"
                                          : "c-pill--danger"
                                      }`}
                                    >
                                      {cubicalItems?.status === true
                                        ? "Active"
                                        : "Suspended"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="table-action">
                                      <span className="table-check" key={index}>
                                        <input
                                          type="checkbox"
                                          id={`toggleEye${index}`}
                                          onChange={() =>
                                            handleToggleClick(
                                              cubicalItems?.cube_id,
                                              cubicalItems?.status
                                            )
                                          }
                                          checked={
                                            cubicalItems?.status === true
                                              ? true
                                              : false
                                          }
                                        />
                                        <label htmlFor={`toggleEye${index}`} />
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                      {boxItemsDetail?.cubicle_details?.length === 0 ? (
                        <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                          <h2 className="m-0">No data found</h2>
                        </div>
                      ) : null}
                      <Pagination
                        totalPages={boxItemsDetail?.totalPage}
                        currentPage={pageNumber}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        handleRowLimitChange={handleRowLimitChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Add modal */}
          <div
            className="modal fade common-modal"
            id="addCubicle"
            tabIndex={-1}
            aria-labelledby="addCubicleLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    id="close-btn"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => resetForm()}
                  />
                  <div className="common-box-body">
                    <h2>Add Cubicle Details</h2>
                    <div className="my-3">
                      <h2 className="">Box ID: {cubeId}</h2>
                    </div>
                    <div className="common-modal-form">
                      <div className="common-modal-field">
                        <label>Enter CubeNumber</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter cube number"
                          name="cubeNumber"
                          value={values.cubeNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.cubeNumber && errors.cubeNumber && (
                          <p className="error">{errors.cubeNumber}</p>
                        )}
                      </div>
                      <div className="common-modal-field">
                        <label>
                          Link Key ring QR <Link to="">Scan QR</Link>
                        </label>
                      </div>
                    </div>
                    <div className="common-modal-btns">
                      <button
                        className="btn primary-btn"
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomLaoder>
    </div>
  );
};

export default Box_management_detail;
